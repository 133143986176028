@import url('https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,900&display=swap');
:root {
  --green-1: #26643b;
  --green-2: #a2de96;
  --lightGreen-1: #d6e5d8;
  --lightGreen-2: #f5fcf4;
  --black-1: #3b413a;
  --black-2: #3f3c3c;
  --black-3: #5b6359;
  --white-1: #e8e8e8;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

html {
  font-size: 62.5%;
  line-height: 1.6rem;
	font-family: 'Roboto', sans-serif;
}

a {
  color: unset;
  text-decoration: none;
}

.btn {
  display: inline-block;
  min-width: 98px;
  padding: 18px 16px;
  border-radius: 12px;
  font-weight: 500;
  font-size: 1.4rem;
  text-align: center;
}

.btn:hover {
  opacity: 0.9;
}

body {
  background: var(--lightGreen-2);
}