.footer {
  background-color: var(--lightGreen-1);
  padding-top: 50px;
  padding-bottom: 20px;
  margin-top: 20px;
  color: var(--color-white);
}

.footer__links {
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
}

.footer__links__title {
  font-family: 'Time New Roman', serif;
  font-size: 5.5rem;
  text-transform: uppercase;
  line-height: 0.8;
  font-weight: 600;
  flex: 0 0 25%;
  max-width: 25%;
  height: 100%;
  border-left: 10px solid currentColor;
  padding-left: 10px;
  color: var(--black-2);
  margin-bottom: 12px;
}

.footer__menu {
  position: relative;
  height: 100%;
  margin-bottom: 3px;
}

/* .footer__menu h1::after {
  display: block;
  content: "";
  position: absolute;
  width: 40px;
  height: 4px;
  background-color: currentColor;
  top: 45px;
  left: 7.5px;
} */

.footer__menu ul {
  margin-top: 30px;
  padding: 0;
  font-size: 1.6rem;
  list-style: none;
  color: var(--black-1);
}

.footer__legal-menu {
  display: flex;
  list-style: none;
}

.footer__information {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.5;
}

.footer__information__site-title {
  text-transform: uppercase;
  padding-right: 4px;
}

.footer__information div {
  display: flex;
}

.footer__legal-menu li:first-child {
  padding-right: 12px;
}