.menu-content {
  margin: 20px 0;
}

.product-category {
  font-weight: 600;
  font-size: 2.5rem;
  margin-top: 5px;
  padding-bottom: 10px;
  border-bottom: solid rgb(216, 216, 216) 1px;
}

.product-card__item {
  margin: 14px auto 10px;
  width: 100%;
  justify-content: center;
}