.product-card__img {
  background-color: rgba(0,0,0,.1);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
  display: block;
  object-fit: cover;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
}

.product-card__img:hover {
  transform: translateY(-8px);
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.22);
}

.product-card__content {
  display: flex;
  padding: 1rem 0;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}

.product-card__content div {
  display: flex;
  flex-direction: column;
}

.product-card__content__price {
  font-size: 2rem;
  font-weight: 600;
  padding: 0 0.5rem;
  color: var(--black-2);
}

.product-card__content__title {
  font-weight: bold;
  font-size: 1.4rem;
  color: var(--black-2);
}

.product-card__content__desc {
  font-weight: 600;
  font-size: 1.2rem;
  color: var(--black-1);
}