.text {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1.5;
  text-align: center;
}

.text a {
  text-decoration: none;
  color: #0645ad;
}

.text a:focus, 
.text a:hover {
  text-decoration: underline;
}