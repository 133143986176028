.banner-content-panel {
  width: 100%;
  padding: 20px 0px;
}

.banner-content {
  width: 70%;
  padding: 0 40px;
  margin-bottom: 60px;
  border-left: 12px solid black;
}

.banner-content h1 {
  font-size: 4.2rem;
  line-height: 3rem;
  margin: 18px 0;
  font-weight: 600;
}

.banner-content p {
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.banner-content-panel iframe {
  outline: 1px solid #ccc;
}
